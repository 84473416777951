<template>
  <!-- v-if="token" -->
  <div>
    <unAuthorized />
    <errorNetwork />

    <!-- ==== DIALOG KONFIRMASI GANTI TOKO ==== -->
    <v-dialog v-model="dialog.confirmation" persistent max-width="450">
      <v-card class="border-radius box-shadow">
        <v-card-text style="height: 400px">
          <v-img
            src="@/assets/images/confirm/confirmation.png"
            class="ml-auto mr-auto"
            max-width="500"
          >
          </v-img>
          <p class="headline font-weight-bold text-center">
            SELAMAT &#127881;
            <!-- <v-icon>mdi-party-popper</v-icon> -->
          </p>
          <p class="mt-5 text-center">
            Anda berhasil pindah ke Event
            <span class="body-1 font-weight-bold">{{ event_change }}</span>
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 mt-3 mx-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="
              dialog.confirmation = false;
              loading();
            "
          >
            <v-icon color="#d31145" small class="mr-1">mdi-check-circle</v-icon>
            Ambil data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG KONFIRMASI LOGOUT ==== -->
    <v-dialog v-model="dialog.logout" persistent max-width="450">
      <v-card class="border-radius box-shadow">
        <v-card-text style="height: 400px">
          <v-img
            src="@/assets/images/confirm/logout.png"
            class="ml-auto mr-auto"
            max-width="500"
          >
          </v-img>
          <p class="headline font-weight-bold text-center">
            Yaaahh &#128553;
            <!-- <v-icon class="ml-2">mdi-emoticon-frown-outline</v-icon> -->
          </p>
          <p class="mt-5 body-2 font-weight-regular text-center">
            Yakin kamu ingin keluar ?
          </p>
        </v-card-text>
        <v-card-actions class="pb-5 mx-3">
          <v-spacer></v-spacer>
          <v-btn
            small
            depressed
            width="100"
            color="#d31145"
            :loading="process.run"
            :disabled="process.run"
            class="white--text text-capitalize"
            @click="logout"
          >
            <v-icon color="#fff" small class="mr-1">mdi-check-circle</v-icon>
            Ya
          </v-btn>
          <v-btn
            small
            outlined
            width="100"
            color="red"
            :disabled="process.run"
            class="text-capitalize"
            @click="dialog.logout = false"
          >
            <v-icon color="red" small class="mr-1">mdi-close-circle</v-icon>
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG KETIKA MEILIH TOKO YANG SAMA ==== -->
    <v-dialog v-model="dialog.store_now" persistent max-width="425">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text v-if="storeReponse == 'same store'">
          Anda tidak dapat berpindah ke Event yang sama .
        </v-card-text>
        <v-card-text v-if="storeReponse == 'gagal'">
          Gagal untuk pindah ke event yang lain .
        </v-card-text>
        <v-card-actions class="pb-1">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="100"
            color="#d31145"
            elevation="0"
            :disabled="process.form"
            :loading="process.form"
            class="white--text text-capitalize"
            @click="dialog.store_now = false"
          >
            Oke
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG PILIH TOKO ==== -->
    <v-dialog v-model="dialog.selectStore" persistent max-width="800">
      <v-card rounded="lg">
        <v-card-text>
          <v-btn
            v-show="event_selected != null"
            class="mt-2 mr-5"
            @click="dialog.selectStore = false"
            icon
            absolute
            right
          >
            <v-icon color="white"> mdi-close-circle </v-icon>
          </v-btn>
          <div
            style="background-color: #d31145; height: 7vh"
            class="rounded-b-xl d-flex justify-center align-center"
            width="100%"
          >
            <span class="white--text overline">Pilih Event</span>
          </div>
        </v-card-text>
        <v-card-text>
          <v-container class="d-flex justify-center align-center">
            <v-slide-group
              v-model="model"
              class="pa-4"
              mandatory
              center-active
              show-arrows
            >
              <v-slide-item
                transition="slide-y-transition"
                v-for="(item, index) in data_event"
                :key="index"
              >
                <v-card
                  color="#ecf0f1"
                  class="ma-4 elevation-0 rounded-lg c-p"
                  v-ripple
                  width="250"
                  @click="
                    event_selected && event_selected.id.toString() === item.id
                      ? storeNow()
                      : update(item)
                  "
                >
                  <v-container>
                    <v-row class="fill-height justify-center">
                      <v-col class="d-flex justify-center" cols="12">
                        <v-avatar tile size="130">
                          <v-img :src="item.image_url ? item.image_url : require('@/assets/image/planner.png')">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0 justify-center"
                                align="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#d31145"
                                >
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12">
                        <div class="text-header-1 text-center">
                          <b>{{ item.name }}</b>
                        </div>
                        <div class="text-header-1 text-center">
                          <i>{{ item.start_date | dateFull }} - {{ item.end_date | dateFull }}</i>
                        </div>
                        <p
                          class="caption grey--text text-center address-text"
                          v-html="item.address">
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== NAVBAR ATAS ==== -->
    <v-app-bar
      style="z-index: 3; margin-left: 13%;"
      fixed
      app
      :color="$vuetify.theme.dark ? 'dark' : '#F7F8FC'"
      elevation="0"
    >
      <v-toolbar-title
        @click="$router.push('/')"
        :class="$vuetify.breakpoint.name === 'xs' ? 'c-p' : 'c-p ml-7 text-uppercase font-weight-bold text-second text-h5'"
      >
        <v-img :width="$vuetify.breakpoint.name === 'xs' ? 70 : 200" height="auto" src="@/assets/soda_live_logo.png"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-left mr-3">
        <v-menu
          bottom
          offset-y
          origin="center center"
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              v-bind="attrs"
              v-on="on"
              class="py-0 pt-0 pb-0 pl-1 my-0">
              <v-list-item-content class="py-0 mt-0" style="width: 250px;">
                <v-list-item-title class="black--text pt-0 text-end mr-4 body-2">
                  {{ user.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-second pt-0 text-end mr-4 caption">
                  {{ user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar
                class="my-0"
                :style="
                $vuetify.breakpoint.name === 'xs' ? 
                  'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(223, 224, 235);'
                : 
                  'height: 45px; min-width: 45px; width: 45px; border: 3px solid rgb(223, 224, 235);'
                "
              >
                <v-img :src="event_selected.image_url ? event_selected.image_url : require('@/assets/icon/calendar.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 justify-center"
                      align="center">
                      <v-progress-circular indeterminate color="#d31145">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
              <v-list-item-icon :class="$vuetify.breakpoint.name === 'xs' ? 'ml-0' : ''">
                <v-icon color="#d31145" right>mdi-menu-down</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list dense class="py-0">
            <div v-for="(item, i) in menuItems" :key="i">
              <v-list-item @click="actionsMenu(item.action)" class="c-p">
                <v-list-item-icon>
                  <v-icon :color="item.color" right>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- ==== NAVIGATION DRAWER BAGIAN SAMPING KIRI ==== -->
    <v-navigation-drawer
      absolute
      dark
      :width="$vuetify.breakpoint.name === 'xl' ? '12%' : '13%'"
      height="100vh"
      permanent
      :src="require('@/assets/navbar-image.png')">
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import unAuthorized from "../components/401.vue";
import errorNetwork from "../components/500.vue";
import store from "@/store";
export default {
  components: {
    unAuthorized,
    errorNetwork,
  },
  data(vm) {
    return {
      comparePath: "",
      model: null,
      drawer: true,
      darkMode: false,
      miniVariant: false,
      initialDark: vm.$vuetify ? vm.$vuetify.theme.dark : false,
      notification: [],
      storeItem: [],
      data_event: [],
      count: 0,
      storeReponse: "",
      event_change: "",
      store_image: "",
      store_address: "",
      menuItems: [
        // {
        //   title: "Informasi Penggunaan",
        //   icon: "mdi-information",
        //   color: "#D31145",
        //   action: "information"
        // },
        {
          title: "Kirim Undangan",
          icon: "mdi-email",
          color: "#D31145",
          action: "invitation"
        },
        {
          title: "Logout",
          icon: "mdi-logout",
          color: "#D31145",
          action: "logout"
        },
      ],
      detail: {},
      dialog: {
        logout: false,
        confirmation: false,
        dialogNotifications: false,
        selectStore: false,
        store_now: false,
        upgradeInfo: false,
      },
      process: {
        run: false,
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.matched.length > 0) {
        this.comparePath = this.$route.path;
        if (to.path == from.matched[0].path) {
          this.$router.push(from.path);
        }
      }
      this.fetchCountNotification();
    },
  },
  computed: {
    errorNetwork() {
      return this.$store.state.common.errorNetwork;
    },
    user() {
      return JSON.parse(TokenService.getUser());
    },
    event_selected() {
      return JSON.parse(localStorage.getItem("event_selected"));
    },
    token() {
      return this.$store.state.token;
    },
  },
  created() {
  },
  mounted() {
    if (this.token) {
      var date1 = new Date("06/30/2019");
      var date2 = new Date("07/30/2019");

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      this.fetchCountNotification();
      this.getEvent()
    }

    if (this.token && JSON.parse(localStorage.getItem('event_selected')) == null) {
      this.dialog.selectStore = true;
    }
  },
  methods: {
    async fetchNotification() {},
    async fetchCountNotification() {},
    storeNow() {
      this.dialog.store_now = true;
      this.storeReponse = "same store";
    },
    update(selected) {
      localStorage.setItem('event_selected', JSON.stringify(selected))
      this.dialog.selectStore = false;
      window.location.reload();
    },
    async getEvent() {
      this.process.run = true
      await get(`eo/event`)
      .then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.data_event = res.results.data
          localStorage.setItem("event", JSON.stringify(res.results.data))
        }
      })
    },
    convert(item) {
      return item.replace(/&lt;/g, "<");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      window.location.reload();
    },
    actionsMenu(action) {
      if (action == "logout") {
        return this.logout();
      }

      if (action == "invitation") {
        return this.$router.push('/invitation');
      }

    },
    logout() {
      this.process.run = true;
      TokenService.removeToken();
      localStorage.clear();
      window.location = "/login";
    },
  },
};
</script>

<style>
.c-default {
  color: #f0932b !important;
}
.divider-menu {
  min-height: 50px !important;
  height: 50px;
  width: 26px;
  min-width: 8px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  border-radius: 10px 0px 0px 10px;
  position: relative;
  right: -1px;
}
.headerthing {
  background-color: #d31145;
  height: 8vh;
}
.c-p {
  cursor: pointer;
}

.bar-app {
  background: linear-gradient(to bottom, #f0932b 0%, #bf6500 100%);
}
.ml-80 {
  margin-left: 20%;
}
.hover-menu:hover,
.actived {
  background: #d311451c !important;
}
</style>
