import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { TokenService } from './service/Storage.Service'

import Convert from "./plugins/Convert"
import VeeValidate from "./plugins/VeeValidate"
import VueApexCharts from "./plugins/VueApexCharts"
import VueCtkDateTimePicker from './plugins/VueCtkDateTimePicker'

import '@/assets/css/main.css'

if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
  store.commit("setSerial", TokenService.getSerialNumber())
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Convert,
  VeeValidate,
  VueApexCharts,
  VueCtkDateTimePicker,
  render: h => h(App)
}).$mount('#app')
