<template>
  <v-app>

    <Navbar
      v-if="
        $route.name !== 'Login' &&
        $route.name !== 'Event Invitation' &&
          $route.name !== '404'
      "
    />
    <!-- :style="$route.name === 'dashboard' ? 'padding: 0px 0px 0px 90px;' : ''" -->
    <v-main min-height="100vh" light style="background: #F7F8FC">
      <v-snackbar
        v-model="dialog.upgrade"
        bottom
        right
        vertical
        timeout="-1"
      >
        <p class="mt-5 font-weight-bold text-center">
          Versi baru telah tersedia.
        </p>
        <p class="mt-2 text-center">
          Silahkan klik tombol Refresh untuk mendapatkan versi terbaru.
        </p>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#fff"
            v-bind="attrs"
            @click.stop="dialog.upgrade = false"
          >
            Nanti
          </v-btn>
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            Refresh App
          </v-btn>
        </template>
      </v-snackbar>
      <router-view />
    </v-main>

    <Footer 
      v-if="
        $route.name === 'Event Invitation'"
    />
  </v-app>
</template>

<script>
import { get, post, put, destroy } from "./service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import firebaseapp from "firebase";
import firebase from "./plugins/Firebase";
export default {
  data() {
    return {
      fab: true,
      connection: null,
      dialog: {
        upgrade: false,
      },
      refreshing: false,
      registrasi: null,
      updateExists: false,
    };
  },
  components: {
    Navbar,
    Footer
  },
  created() {
    if (process.browser) {
      if ("Notification" in window && "serviceWorker" in navigator) {
        this.tokenFirebase();
      }
    }

    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    // $route(to, from) {
    //   this.tokenFirebase();
    //   if (this.$route.path != "/login") {
    //     this.preventPrevilage();
    //   }
    // },
    
  },
  mounted() {},
  methods: {
    tokenFirebase() {
      if (firebase.messaging.isSupported()) {
        if (!this.token) {
          navigator.permissions
            .query({ name: "notifications" })
            .then(function(result) {
              if (result.state === "granted") {
                firebaseapp
                  .messaging()
                  .getToken()
                  .then((oldToken) => {
                    if (oldToken === localStorage.getItem("TOKEN_FCM")) {
                      // console.log("Token delete ", oldToken);
                      firebaseapp.messaging().deleteToken({ token: oldToken });
                      // localStorage.setItem('TOKEN_FCM', '')
                    } else {
                      console.log("Token is deleted");
                    }
                  });
              }
            });
        } else {
          navigator.permissions
            .query({ name: "notifications" })
            .then(function(result) {
              if (result.state !== "granted") {
                firebaseapp
                  .messaging()
                  .Notification.requestPermission()
                  .then(function() {
                    window.location.reload();
                  })
                  .catch(function(err) {
                    console.log("Unable to get permission to notify", err);
                  });
              }
            });

          firebaseapp
            .messaging()
            .getToken()
            .then((refreshedToken) => {
              // console.log("Token refresh ", refreshedToken);
              this.updateTokenFirebase(refreshedToken);
            })
            .catch(function(err) {
              console.log("Unable to retrieve token", err);
            });
        }
      }
    },
    async updateTokenFirebase(refreshedToken) {
      if (refreshedToken !== localStorage.getItem("TOKEN_FCM")) {
        localStorage.setItem("TOKEN_FCM", refreshedToken);
      }
    },
    preventPrevilage() {
      if (TokenService.getUser()) {
        if (!JSON.parse(TokenService.getUser()).user_privilage) {
          TokenService.removeToken();
          window.location = "/login";
        }
      }
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.dialog.upgrade = true;
    },
    refreshApp() {
      this.updateExists = false;
      this.dialog.upgrade = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style>
#price {
  text-align: right;
}
#inputQty {
  text-align: center !important;
}
#halaman {
  text-align: center;
}
.vue-treeselect__placeholder {
  left: 0px !important;
  right: auto !important;
  position: absolute !important;
  color: #616161 !important;
}

.shortcuts-container {
  min-height: 380px !important;
}
.datepicker-container {
  max-height: 400px !important;
}
.vue-treeselect__menu-container {
  z-index: 99 !important;
}
.vue-treeselect__control-arrow {
  color: grey !important;
}
.vue-treeselect__control {
  padding-left: 5px;
  padding-right: 5px;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 36px;
  border: 1px solid #d31145 !important ;
  border-radius: 5px;
  background: #ffffff !important;
  transition-duration: 200ms;
  transition-property: border-color, box-shadow, width, height, background-color,
    opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#content {
  padding: 0px;
}
</style>
