<template>
  <v-dialog v-model="$store.state.unauthorization" persistent max-width="500">
		<v-card class="rounded-xl">
			<v-card-text>
				<v-img src="@/assets/404/401.png"
					class="ml-auto mr-auto"
					max-width="400">
				</v-img>
				<p class="headline font-weight-bold text-center">Oops!</p>
				<p class="font-weight-bold text-center mb-1 px-5" style="font-size: 1.1rem !important;">
          Sesi Anda sudah habis
        </p>
        <p class="body-2 text-center font-weight-regular">
          Silahkan login ulang
        </p>
			</v-card-text>
      <v-card-actions class="pb-5 mx-3">
        <v-spacer></v-spacer>
        <v-btn
          width="150"
          depressed
          color="#d31145"
          class="text-capitalize white--text"
          @click="logout">
          Oke
        </v-btn>
      </v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { TokenService } from "@/service/Storage.Service";
export default {
  props: {
    msg: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
    toPage: {
      type: String,
      default: ''
    },
    show: {
      type: Number,
      default: 1
    }
  },
  methods: {
    logout() {
      TokenService.removeToken()
      this.$store.state.alert = false
      window.location = '/login'
    }
  }
}
</script>

<style>

</style>